import React from 'react'
import {HiArrowNarrowRight} from 'react-icons/hi'
export const Home = () => {
  return (
    <div name = 'Home'className='w-full h-screen bg-[#0a192f] '>
      {/*Continer*/}

    <div className='max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full'>
      <p className='text-green-600 '>Hi, My name is</p>
      <h1 className='text-4xl sm:text-7xl font-bold text-[#ccd6f6]'>Omer Aqeel</h1>
      <h2 className='text-4xl sm:text-7xl font-bold text-[#8892b0] '>I'm a Computer Science Student.</h2>
      <p className = 'text-[#8892b0] py-4 max-w-[700px]'>I'm a Computer Science student specialising in Aritificial Intelligence at Brunel University London, I'm also a Data Science and Machine Learning enthusiast. I'm currently developing machine learning skills after which I will start working on projects that focus on building a machine learning model to predict various solutions for various problems.</p>
      <div>
        <button className='text-white border-2 px-6 py-3 my-2 flex items-center hover:bg-green-600 hover:border-green-600'>View Work 
        <span className='hover:rotate-90 duration-300'>
        <HiArrowNarrowRight className='ml-3'/>
        </span>
        </button>
      </div>
    </div>
    </div>
  )
}
