import React from 'react'

export const  About = () => {
  return (
    <div name = 'about' className='w-full h-screen bg-[#0a192f] text-gray-300'>
        <div className='flex flex-col justify-center items-center w-full h-full'>
            <div className='max-w-[1000px] w-full grid grid-cols-2 gap-8'>
                <div className='sm:text-right pb-8 pl-4 '>
                    <p className='text-4xl font-bold inline border-b-4 border-green-600'>About</p>
                </div>
                <div>
                </div>
            </div>
                <div className='max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4'>
                    <div className='sm:text-right text-4xl font-bold '>
                    <p>Hi. I'm Omer, nice to meet you. Please take a look around.</p>
                    </div>
                    <div>
                        <p>An ambitious and determined individual with big dreams and having the capability to fulfil them with hard work and dedication. I aim to build a career in IT after completing my degree, as I am highly passionate about modern technologies and the future of technology. I have limited work experience at the moment, but I do have determination and enthusiasm towards achieving my goal.</p>
                    </div>
                </div>
        </div>

    </div>
  )
}

export default About
